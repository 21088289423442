import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SimpleInnerPage from "../components/simple-inner-page"
import security from "../images/security.svg"
import { breakpoints } from "../components/variables"

const Paragraph = styled.p`
  font-size: 1.5rem;
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1rem;
  }
`
const Link = styled.a`
  color: ${props => props.theme.palette.datamap.light};
`
const IndexPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <SimpleInnerPage
      title="Privacy Policy"
      date="February 2020"
      icon={security}
    >
      <Paragraph>This is the website of DataMap.tools</Paragraph>

      <Paragraph>
        Our postal address is:
        <br />
        70 Santa Felicia Drive
        <br />
        Goleta, CA 93117
      </Paragraph>

      <Paragraph>We can be reached via e-mail at info@DataMap.tools</Paragraph>

      <Paragraph>
        For each visitor to our Web page, our Web server automatically
        recognizes only the consumer's domain name, but not the e-mail address
        (where possible).
      </Paragraph>

      <Paragraph>
        We collect the e-mail addresses of those who post messages to our
        bulletin board, the e-mail addresses of those who communicate with us
        via e-mail, the e-mail addresses of those who make postings to our chat
        areas, information volunteered by the consumer, such as survey
        information and/or site registrations, name and address, telephone
        number.
      </Paragraph>

      <Paragraph>
        The information we collect is used to improve the content of our Web
        page, , disclosed when legally required to do so, at the request of
        governmental authorities conducting an investigation, to verify or
        enforce compliance with the policies governing our Website and
        applicable laws or to protect against misuse or unauthorized use of our
        Website, to a successor entity in connection with a corporate merger,
        consolidation, sale of assets or other corporate change respecting the
        Website.
      </Paragraph>

      <Paragraph>
        With respect to cookies: We use cookies to store visitors’ preferences,
        record session information, such as items that consumers add to their
        shopping cart.
      </Paragraph>

      <Paragraph>
        If you do not want to receive e-mail from us in the future, please let
        us know by or click the auto-unsubscribe link from the footer of any of
        our Emails.
      </Paragraph>

      <Paragraph>
        From time to time, we make the e-mail addresses of those who access our
        site available to other reputable organizations whose products or
        services we think you might find interesting. If you do not want us to
        share your e-mail address with other companies or organizations, please
        let us know by calling us at the number provided above.
      </Paragraph>

      <Paragraph>
        From time to time, we make our customer e-mail list available to other
        reputable organizations whose products or services we think you might
        find interesting. If you do not want us to share your e-mail address
        with other companies or organizations, please let us know by calling us
        at the number provided above.
      </Paragraph>

      <Paragraph>
        If you supply us with your postal address online you will only receive
        the information for which you provided us your address.
      </Paragraph>

      <Paragraph>
        Persons who supply us with their telephone numbers on-line will only
        receive telephone contact from us with information regarding orders they
        have placed on-line.
      </Paragraph>

      <Paragraph>
        Persons who supply us with their telephone numbers on-line or Customer
        support issues.
      </Paragraph>

      <Paragraph>
        Please provide us with your name and phone number. We will be sure your
        name is removed from the list we share with other organizations
      </Paragraph>
      <Paragraph>
        With respect to Ad Servers: We do not partner with or have special
        relationships with any ad server companies.
      </Paragraph>

      <Paragraph>
        From time to time, we may use customer information for new,
        unanticipated uses not previously disclosed in our privacy notice. If
        our information practices change at some time in the future we will post
        the policy changes to our Web site to notify you of these changes and we
        will use for these new purposes only data collected from the time of the
        policy change forward. If you are concerned about how your information
        is used, you should check back at our Web site periodically.
      </Paragraph>

      <Paragraph>
        Customers may prevent their information from being used for purposes
        other than those for which it was originally collected by e-mailing us
        at the above address.
      </Paragraph>

      <Paragraph>
        Upon request we provide site visitors with access to communications that
        the consumer/visitor has directed to our site (e.g., e-mails, customer
        inquiries), no information that we have collected and that we maintain
        about them.
      </Paragraph>

      <Paragraph>
        Upon request, we offer visitors no ability to have factual inaccuracies
        corrected in information that we maintain about them
      </Paragraph>

      <Paragraph>
        With respect to security: When we transfer and receive certain types of
        sensitive information such as financial or health information, we
        redirect visitors to a secure server and will notify visitors through a
        pop-up screen on our site, We have appropriate security measures in
        place in our physical facilities to protect against the loss, misuse or
        alteration of information that we have collected from you at our site.
      </Paragraph>

      <Paragraph>
        If you feel that this site is not following its stated information
        policy, you may contact us at the above addresses or phone number, state
        or local consumer protection office, The Federal Trade Commission by
        phone at 202.FTC-HELP (202.382.4357) or electronically at &nbsp;
        <Link
          href="http://www.ftc.gov/ftc/complaint.htm"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.ftc.gov/ftc/complaint.htm
        </Link>
      </Paragraph>

      <Paragraph>
        <Link
          href="http://www4.law.cornell.edu/uscode/5/552a.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Act
        </Link>
      </Paragraph>
      <Paragraph>
        <Link
          href="http://www4.law.cornell.edu/uscode/5/552.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Freedom of Information Act
        </Link>
      </Paragraph>

      <Paragraph>
        Questions concerning our privacy policy may be addressed to &nbsp;
        <b>info@DataMap.tools.</b>
      </Paragraph>
    </SimpleInnerPage>
  </Layout>
)

export default IndexPage
